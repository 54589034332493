import { onMounted, ref } from 'vue'
import { get } from '../request'

export function faqListModel() {
    let listRef = ref([])
    const refresh = async () => {
        const params = { cate_id: 1 }
        const res = await get({ url: '/rf-article/article/index', params }, { loading: true })
        const data = res.data || []
        listRef.value = data
    }
  
    onMounted(() => {
      refresh()
    })
  
    return { listRef }
}

export function articleSingleModel({ id = 0 }) {
    const contentRef = ref({})
    const refresh = async () => {
        const params = { id }
        const res = await get({ url: '/rf-article/article-single/view', params }, { loading: true })
        contentRef.value = res.data
    }

    onMounted(()=> {
        refresh()
    })

    return { contentRef }
}

export function aboutModel() {
    const { contentRef } = articleSingleModel({ id: 1 })
    return { contentRef }
}

export function licenseModel() {
    const { contentRef } = articleSingleModel({ id: 2 })
    return { contentRef }
}

export function registerLicenseModel() {
    const { contentRef } = articleSingleModel({ id: 3 })
    return { contentRef }
}

export function eventModel() {
    const { contentRef } = articleSingleModel({ id: 4 })
    return { contentRef }
}

export function noticeModel() {
    let contentRef = ref({})
    
    async function getContentFunc() {
        let res = await get({url: '/rf-article/article/notice'}, {loading: true})
        if(res.code != 200) return
        contentRef.value = res.data||{}
    }

    onMounted(()=> {
        getContentFunc()
    })

    return { contentRef }
}

export var messageNoReadCountRef = ref(0)
export function messageModel(init) {
    let pageRef = ref(1)
    let pageSize = 200
    let messageListRef = ref([])

    async function messageListFunc(_init, _read) {
        if(_init) {
            pageRef.value = 1
        }
        let data = {
            offset: (pageRef.value-1)*pageSize,
            limit: pageSize
        }
        let res = await get({url: '/rf-article/notify/index', params: data}, {loading: true, toast: false})
        if(res.code != 200) {
            return
        }
        let list = res.data||[]
        for(let i in list) {
            if(list[i].read != 1) {
                messageNoReadCountRef.value ++
                break
            }
        }
        messageListRef.value = list
        if(_read) {
            messageReadFunc()
        }
    }
    async function messageReadFunc() {
        for(let i in messageListRef.value) {
            if(messageListRef.value[i].read != 1) {
                messageNoReadCountRef.value = 0
                await get({url: '/rf-article/notify/view', params: {id: messageListRef.value[i].id}}, {loading: false, toast: false})
            }
        }
    }

    onMounted(()=> {
        if(init) messageListFunc(true)
    })

    return { pageRef, messageListRef, messageListFunc, messageReadFunc }
}

export const noticePopupShowRef = ref(false)
export const noticeHashPopupShowRef = ref(false)
let noticePopupContentRef = ref({})

export function noticePopupModel(init) {

    async function noticePopupFunc() {
        let res = await get({url: '/rf-article/article/notice'}, {loading: true, toast: false})
        noticePopupContentRef.value = res.data||{}
        if(res.data&&res.data.content) {
            noticePopupShowRef.value = true
            noticeHashPopupShowRef.value = true
        }
    }

    onMounted(()=> {
        if(init && !noticeHashPopupShowRef.value) noticePopupFunc()
    })

    return { noticePopupContentRef, noticePopupFunc }
}