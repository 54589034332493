export default {
    "title": "🇳🇱 Nederlands",

    "首页": "Home",
    "关于": "Over",
    "会员": "Lidmaatschap",
    "推荐计划": "Aanbevelingsprogramma",
    "个人": "Persoonlijk",
    "推荐": "Aanbevelen",
    "预订": "Reserveren",
    "事件": "Gebeurtenissen",
    "登出": "Uitloggen",
    "登录": "Inloggen",
    "注册": "Registreren",
    "我们的应用程序现在可用": "Onze app is nu beschikbaar",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Voor een betere ervaring hebben we zojuist onze mobiele app gelanceerd voor naadloos en sneller boeken.",
    "iOS版下载（即将推出）": "Download iOS-app (binnenkort beschikbaar)",
    "Android版下载（即将推出）": "Download Android-app (binnenkort beschikbaar)",
    "为什么选择我们": "Waarom voor ons kiezen",
    "我们卓越的服务。": "Onze uitstekende service.",
    "收入最高的酒店": "Hotels met de hoogste inkomsten",
    "我们的合作酒店收入最高。": "Onze partnerhotels hebben de hoogste inkomsten.",
    "回报": "Terugverdienen",
    "最具特色的酒店": "Meest unieke hotels",
    "我们平台上最有特色的酒店。": "De meest unieke hotels op ons platform.",
    "热门国家和目的地": "Populaire landen en bestemmingen",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Ontvang enkele van de beste tips van overal ter wereld via onze partners en vrienden.",
    "酒店": "Hotels",
    "预订酒店并开始赚取": "Boek hotels en begin met verdienen",
    "让我们开始与TitanTraveler一起探索世界": "Laten we samen met TitanTraveler de wereld gaan verkennen",
    "客户评价": "Klantbeoordelingen",
    "查看我们用户的真实评价。": "Bekijk de echte beoordelingen van onze gebruikers.",
    "银行卡列表": "Bankkaartlijst",

    "银行列表": "Banklijst",
    "持卡人姓名": "Naam kaarthouder",
    "银行卡号": "Bankkaartnummer（ IBAN ）",
    "支付密码": "Betalingswachtwoord",
    "提交": "Indienen",
    "Pix列表": "Pix-lijst",
    "Pix类型": "Pix-type",
    "Pix账号": "Pix-account",
    "账号列表": "Accountlijst",
    "网络": "Netwerk",
    "地址": "Adres",
    "备注": "Opmerking",
    "充值金额": "Oplaadbedrag",
    "支付类型": "Betalingstype",
    "支付银行": "Betalingsbank",
    "USDT地址": "USDT-adres",
    "二维码": "QR-code",
    "充值渠道": "Oplaadkanaal",
    "充值凭证": "Oplaadbewijs",
    "立即登录": "Direct inloggen",
    "用户名": "Gebruikersnaam",
    "密码": "Wachtwoord",
    "邀请码": "Uitnodigingscode",
    "确认密码": "Bevestig wachtwoord",
    "手机": "Mobiel",
    "注册2": "Registreren",
    "金额": "Bedrag",
    "新增": "Toevoegen",

    "我们的精彩": "Onze hoogtepunten",
    "故事": "Verhaal",
    "奖项": "Prijzen",
    "获得": "Verkrijgen",
    "钱包余额": "Portemonneebalans",
    "今日奖励": "Beloning vandaag",
    "总行程": "Totale reizen",
    "访问行程": "Bezoekreis",
    "酒店历史": "Bestelgegevens",
    "单价": "Prijs per stuk",
    "数量": "Hoeveelheid",
    "总额": "Totaalbedrag",
    "奖励": "Beloning",
    "上一页": "Vorige pagina",
    "下一页": "Volgende pagina",
    "每套预订量": "Aantal boekingen per set",
    "预订奖励": "Boekingsbeloning",
    "每日预订": "Dagelijkse boeking",
    "手机号码": "Telefoonnummer",
    "修改密码": "Wachtwoord wijzigen",
    "登录密码": "Inlogwachtwoord",
    "当前密码": "Huidig wachtwoord",
    "新密码": "Nieuw wachtwoord",
    "确认新密码": "Bevestig nieuw wachtwoord",
    "保存修改": "Wijzigingen opslaan",
    "充值": "Opladen",
    "我们的": "Onze",
    "复制": "Kopiëren",

    "分享链接": "Deel link",
    "搜索酒店": "Zoek hotels",
    "实际等级": "Daadwerkelijke rang",
    "升级": "Upgraden",
    "已完成预订": "Voltooide boekingen",
    "登录时间": "Inlogtijd",
    "信誉分": "Kredietwaardigheidsscore",
    "控制菜单": "Besturingsmenu",
    "提现": "Opnemen",
    "提现账号": "Opnameaccount",
    "你确定要删除此账号吗": "Weet je zeker dat je dit account wilt verwijderen?",
    "你确定要删除此地址吗": "Weet je zeker dat je dit adres wilt verwijderen?",
    "提示": "Tip",
    "是": "Ja",
    "否": "Nee",
    "全部": "Alles",
    "待处理": "In behandeling",
    "冻结中": "Bevroren",
    "已完成": "Voltooid",
    "订阅": "Abonneren",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Ontvang graag meldingen wanneer we nieuwe sjablonen of updates uitbrengen. Registreer en we sturen u een e-mailmelding.",
    "我们的伙伴": "Onze partners",
    "关于我们": "Over ons",
    "实时聊天支持": "Live chatondersteuning",
    "在线聊天": "Online chatten",
    "创建时间": "Aanmaaktijd",

    "你确定要登出当前账号吗？": "Weet je zeker dat je wilt uitloggen van dit account?",
    "请输入你的邮箱": "Voer je e-mailadres in",
    "好评": "Positieve recensies",
    "条评论": "recensies",
    "平均每晚": "Gemiddeld per nacht",
    "旅行旅程": "Reisroute",
    "正在寻找酒店": "Op zoek naar hotels",
    "开始预订": "Begin met boeken",
    "旅程价格": "Reiskosten",
    "佣金": "Commissie",
    "底部-about": "Wij zijn een digitaal marketingbureau gevestigd in Duitsland dat bedrijven in staat stelt succes te behalen in de digitale wereld door middel van innovatieve en op data gebaseerde marketingstrategieën, gepersonaliseerde service en een groeimentaliteit die de pols van de industrietrends vasthoudt. Of u nu behoefte heeft aan een SEO-bureau, een websiteontwerper, pay-per-click-beheer, merkstrategie, inhoudsontwikkeling of andere digitale marketingdiensten, Dertour Group heeft de expertise en ervaring om u te helpen uw doelen te bereiken in een resultaatgerichte wereld.",
    "立即预订": "Nu boeken",
    "搜索": "Zoeken",
    "快乐的客户": "Tevreden klanten",
    "快乐的酒店老板": "Blije hoteliers",
    "评论1": "Een verbazingwekkend platform, krachtig en zeker de moeite van het verkennen waard!",
    "评论2": "Als je onduidelijkheden hebt over het platform, zal proactieve klantenservice je begeleiden.",
    "评论3": "Betrouwbaar platform, zeker het proberen waard!",
    "评论4": "Ik slaagde erin mijn hotel hier te vermelden en goede marketingresultaten te behalen.",
    "推荐计划1": "Gebruikers van het platform kunnen anderen uitnodigen om agent van het platform te worden met een verwijzingscode en zo uw downline vormen.",
    "推荐计划2": "Als upline kunt u een bepaald percentage innen van de winst van uw downline; de winst die de upline maakt, wordt rechtstreeks teruggestort naar de rekening van de upline of naar het teamoverzicht.",
    "推荐计划3": "Opmerking: alle agenten/downlines van het platform ontvangen een bepaald percentage van de winst en beloningen van het platform; het bouwen van een team heeft geen invloed op de winst en beloningen van welke agent of downline dan ook.",
    "评分": "Beoordeling",
    "评论": "Recensie",

    "酒店很棒": "Hotel is geweldig",
    "终于体验了，酒店非常好": "Eindelijk ervaren, het hotel is erg goed",
    "我对酒店非常满意并推荐预订": "Ik ben zeer tevreden over het hotel en raad aan om te boeken",
    "我很高兴很满意，价格实惠": "Ik ben erg blij en tevreden, de prijs is betaalbaar",
    "服务非常好，我非常喜欢": "Service is erg goed, ik vind het erg leuk",
    "体验很不错，朋友已经第二次预订了": "De ervaring is erg goed, vrienden hebben al voor de tweede keer geboekt",
    "酒店环境优美": "Hotelomgeving is prachtig",
    "酒店卫生干净": "Hotel is schoon en hygiënisch",
    "工作人员态度非常好": "Personeel is zeer hoffelijk",
    "酒店服务很棒": "Hotelservice is geweldig",
    "你还没有设置支付密码，请前往设置": "Je hebt nog geen betalingswachtwoord ingesteld, ga naar de instellingen",
    "订单": "Bestelling",
    "复制成功": "Kopiëren gelukt",
    "团队与条件": "Team & voorwaarden",
    "站内信": "Site berichten",
    "请联系客服升级会员": "Neem contact op met de klantenservice om uw lidmaatschap te upgraden",
    "我已阅读并同意": "Ik heb het gelezen en ga ermee akkoord",
    "注册协议": "Registratieovereenkomst",
    "忘记密码": "Vergeet het wachtwoord",
    "账户明细": "Accountgegevens",
    "时间": "Tijd",
    "类型": "Type",
    "代理支持": "Agent-ondersteuning",
    "冻结金额": "Bevriezen bedrag",

    "点击下面的金蛋抽取奖励": "Klik op het gouden ei hieronder om beloningen te trekken",
    "恭喜您中奖了！": "Gefeliciteerd met het winnen!",
    "很遗憾，您没有中奖": "Sorry, je hebt niet gewonnen",
    "公告": "aankondiging",
}