<script setup>
import { computed } from "vue"
import { t } from '@/i18n'
import { mediaTypeRef } from '@/model/media'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { appNameRef, configMOdel } from '@/model/basic'

const { webListRef, toCustomerURLFunc } = configMOdel(true)

const logoItemsToShow = computed(()=> {
    let _val = mediaTypeRef.value
    let _res = 3
    if(_val == '2xl' || _val == 'xl') {
        _res = 5
    }else if( _val == 'lg' || _val == 'md') {
        _res = 4
    }else {
        _res = 3
    }
  return _res
})
</script>

<template>
    <footer class="w-full ctx-theme block overflow-hidden">
        <section class="w-full bg-minor" :style="'background-image: url('+require('@/assets/img/bg-wave.png')+')'">
            <div class="py-10 md:py-16 lg:py-[90px] ctx-wrap relative">
                <!-- <dl class="-mx-2 flex flex-wrap">
                    <dd class="w-full lg:w-1/2 p-2 block">
                        <h5 class="mb-4 text-2xl font-bold">{{t('订阅')}}</h5>
                        <p class="text-sm opacity-70">{{t('希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。')}}</p>
                    </dd>
                    <dd class="w-full lg:w-1/2 p-2 lg:pl-10 block">
                        <div class="w-full h-16 p-2 bg-white/30 rounded flex items-center">
                            <div class="py-2 px-4 bg-white text-black rounded-l h-full flex-1 overflow-hidden">
                                <input type="text" :placeholder="t('请输入你的邮箱')" class="w-full h-full text-base lg:text-sm placeholder:text-sm placeholder:text-gray-default outline-none">
                            </div>
                            <button class="h-full px-4 ctx-theme text-sm rounded-r flex items-center">
                                <svg class="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM96 136c0-13.3 10.7-24 24-24c137 0 248 111 248 248c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-110.5-89.5-200-200-200c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24c83.9 0 152 68.1 152 152c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-57.4-46.6-104-104-104c-13.3 0-24-10.7-24-24zm0 120a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                </svg>
                                <span>{{t('订阅')}}</span>
                            </button>
                        </div>
                    </dd>
                </dl> -->
                <div class="pt-6 pl-11 pr-8 pb-4 bg-black bg-opacity-[0.05] border border-white/10 relative">
                    <div class="w-full relative flex">
                        <div class="flex-1 overflow-hidden">
                            <p class="text-xs font-medium">{{t('实时聊天支持')}} :</p>
                        </div>
                        <button @click="toCustomerURLFunc()" class="px-5 h-12 text-xs ctx-third hover:bg-minor font-medium rounded cursor-pointer flex items-center">
                            <span>{{t('在线聊天')}}</span>
                            <svg class="w-4 h-4 ml-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M4.02381 3.78307C4.12549 3.32553 4.5313 3 5 3H19C19.4687 3 19.8745 3.32553 19.9762 3.78307L21.9762 12.7831C21.992 12.8543 22 12.927 22 13V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V13C2 12.927 2.00799 12.8543 2.02381 12.7831L4.02381 3.78307ZM5.80217 5L4.24662 12H9C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12H19.7534L18.1978 5H5.80217ZM16.584 14C15.8124 15.7659 14.0503 17 12 17C9.94968 17 8.1876 15.7659 7.41604 14H4V19H20V14H16.584Z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full py-[70px] border-t border-black/20 relative">
            <em class="w-full h-full bg-fill absolute left-0 top-0 opacity-[0.04]" :style="'background-image: url('+require('@/assets/img/bg-footer.jpg')+')'"></em>
            <div class="ctx-wrap relative">
                <div class="w-full flex lg:items-center flex-wrap">
                    <div class="w-full lg:w-1/4">
                        <h5 class="text-sm font-bold">{{t('我们的伙伴')}}</h5>
                    </div>
                    <div v-if="webListRef&&webListRef.length" class="w-full lg:w-3/4">
                        <Carousel id="footer-logo" :items-to-show="logoItemsToShow" :wrap-around="true" snapAlign="start">
                            <Slide v-for="(item,index) in webListRef" :key="index">
                                <div class="w-full px-2 opacity-60">
                                    <WiImage :src="item.img" class="w-full h-24" emptyStyle="background-color: rgba(255,255,255,0.5);color: white;" />
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                            </template>
                        </Carousel>
                    </div>
                </div>
                <i class="w-full pt-8 mb-16 border-b border-white/20 block"></i>
                <div class="w-full md:w-1/2 lg:w-1/3">
                    <h5 class="pb-6 mb-8 text-sm font-bold border-b border-white/20">{{t('关于我们')}}</h5>
                    <div class="text-sm leading-7">
                        <p>{{t('底部-about')}}</p>
                    </div>
                </div>
                <div class="w-full mt-14">
                    <dl class="-mx-2 flex flex-wrap">
                        <dd class="w-full lg:w-1/2 p-2 block">
                            <h5 class="mb-4 text-2xl font-bold">{{t('订阅')}}</h5>
                            <p class="text-sm opacity-70">{{t('希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。')}}</p>
                        </dd>
                        <dd class="w-full lg:w-1/2 p-2 lg:pl-10 block">
                            <div class="w-full h-16 p-2 bg-white/30 rounded flex items-center">
                                <div class="py-2 px-4 bg-white text-black rounded-l h-full flex-1 overflow-hidden">
                                    <input type="text" :placeholder="t('请输入你的邮箱')" class="w-full h-full text-base lg:text-sm placeholder:text-sm placeholder:text-gray-default outline-none">
                                </div>
                                <button class="h-full px-4 ctx-theme text-sm rounded-r flex items-center">
                                    <svg class="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM96 136c0-13.3 10.7-24 24-24c137 0 248 111 248 248c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-110.5-89.5-200-200-200c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24c83.9 0 152 68.1 152 152c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-57.4-46.6-104-104-104c-13.3 0-24-10.7-24-24zm0 120a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                    </svg>
                                    <span>{{t('订阅')}}</span>
                                </button>
                            </div>
                        </dd>
                    </dl>
                    <!-- <div class="pt-6 pl-11 pr-8 pb-4 bg-black bg-opacity-[0.05] border border-white/10 relative">
                        <div class="w-full relative flex">
                            <div class="flex-1 overflow-hidden">
                                <p class="text-xs font-medium">{{t('实时聊天支持')}} :</p>
                            </div>
                            <button @click="toCustomerURLFunc()" class="px-5 h-12 text-xs ctx-third hover:bg-minor font-medium rounded cursor-pointer flex items-center">
                                <span>{{t('在线聊天')}}</span>
                                <svg class="w-4 h-4 ml-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M4.02381 3.78307C4.12549 3.32553 4.5313 3 5 3H19C19.4687 3 19.8745 3.32553 19.9762 3.78307L21.9762 12.7831C21.992 12.8543 22 12.927 22 13V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V13C2 12.927 2.00799 12.8543 2.02381 12.7831L4.02381 3.78307ZM5.80217 5L4.24662 12H9C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12H19.7534L18.1978 5H5.80217ZM16.584 14C15.8124 15.7659 14.0503 17 12 17C9.94968 17 8.1876 15.7659 7.41604 14H4V19H20V14H16.584Z"></path>
                                </svg>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
        <section class="w-full pt-8 pb-5 bg-[#061B41] text-white font-medium">
            <div class="ctx-wrap">
                <p class="text-xs">© {{appNameRef}} 2024 . All rights reserved.</p>
            </div>
        </section>
    </footer>
</template>