export default {
    "title": "🇮🇹 Italiano",
    
    "首页": "Home",
    "关于": "Informazioni",
    "会员": "Membri",
    "推荐计划": "Programma di Riferimento",
    "个人": "Profilo",
    "推荐": "Riferimento",
    "预订": "Prenota Ora",
    "事件": "Evento",
    "登出": "Disconnetti",
    "登录": "Accedi",
    "注册": "Registrati",
    "我们的应用程序现在可用": "La nostra app è disponibile ora",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Per un'esperienza migliore, abbiamo appena lanciato la nostra app mobile per prenotazioni più fluide e veloci.",
    "iOS版下载（即将推出）": "Download per iOS (In Arrivo)",
    "Android版下载（即将推出）": "Download per Android (In Arrivo)",
    "为什么选择我们": "Perché Scegliere Noi",
    "我们卓越的服务。": "I nostri servizi eccezionali.",
    "收入最高的酒店": "Attrazioni con Maggiori Introiti",
    "我们的合作酒店收入最高。": "Le nostre attrazioni in collaborazione con i maggiori introiti.",
    "回报": "Ritorni",
    "最具特色的酒店": "Attrazioni Più Caratteristiche",
    "我们平台上最有特色的酒店。": "Hotel più caratteristici sulla nostra piattaforma.",
    "热门国家和目的地": "Paesi e Destinazioni Popolari",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Scopri alcuni dei migliori consigli della città dai nostri partner e amici.",
    "酒店": "Attrazioni",
    "预订酒店并开始赚取": "Esplora il mondo con noi!",
    "让我们开始与TitanTraveler一起探索世界": "Preparati a esplorare il mondo delle attrazioni con noi!",
    "客户评价": "Testimonianze",
    "查看我们用户的真实评价。": "Controlla le nostre testimonianze autentiche dai nostri utenti.",
    "银行卡列表": "Elenco Carte Bancarie",
    "银行列表": "Elenco Banche",
    "持卡人姓名": "Nome del Titolare della Carta",
    "银行卡号": "Numero di Conto Bancario（ IBAN ）",
    "支付密码": "Password di Pagamento",
    "提交": "Invia",
    "Pix列表": "Elenco Pix",
    "Pix类型": "Tipo Pix",
    "Pix账号": "Conto Pix",
    "账号列表": "Elenco Conti",
    "网络": "Rete",
    "地址": "Indirizzo",
    "备注": "Nota",
    "充值金额": "Importo di Ricarica",
    "支付类型": "Tipo di Pagamento",
    "支付银行": "Banca di Pagamento",
    "USDT地址": "Indirizzo USDT",
    "二维码": "Codice QR",
    "充值渠道": "Canale di Ricarica",
    "充值凭证": "Voucher di Ricarica",
    "立即登录": "Accedi Ora",
    "用户名": "Nome Utente",
    "密码": "Password",
    "邀请码": "Codice di Invito",
    "确认密码": "Conferma Password",
    "手机": "Cellulare",
    "注册2": "Iscriviti",
    "金额": "Importo",
    "新增": "Nuovo",
    "我们的精彩": "Il Nostro Fantastico",
    "故事": "Storia",
    "奖项": "Premi",
    "获得": "Guadagnati",
    "钱包余额": "Saldo del Portafoglio",
    "今日奖励": "Ricompense di Oggi",
    "总行程": "Viaggio Totale",
    "访问行程": "Viaggi Visitati",
    "酒店历史": "Dettagli dell'ordine",
    "单价": "Prezzo",
    "数量": "Numero",
    "总额": "Totale",
    "奖励": "Ricompense",
    "上一页": "Precedente",
    "下一页": "Successivo",
    "每套预订量": "Prenotazioni per Set",
    "预订奖励": "Ricompensa Prenotazione",
    "每日预订": "Prenotazione Giornaliera",
    "手机号码": "Numero di Telefono",
    "修改密码": "Cambia Password",
    "登录密码": "Password di Accesso",
    "当前密码": "Password Corrente",
    "新密码": "Nuova Password",
    "确认新密码": "Conferma Nuova Password",
    "保存修改": "Salva Modifiche",
    "充值": "Ricaricare",
    "我们的": "Nostro",
    "复制": "Copia",
    "分享链接": "Condividi Link",
    "搜索酒店": "Prenota Viaggio",
    "实际等级": "Livello Attuale",
    "升级": "Aggiornare",
    "已完成预订": "Prenotazioni Completate",
    "登录时间": "Tempo di Accesso",
    "信誉分": "Credibilità",
    "控制菜单": "Menu di Controllo",
    "提现": "Prelievo",
    "提现账号": "Conto di Prelievo",
    "你确定要删除此账号吗": "Sei sicuro di voler eliminare questo conto?",
    "你确定要删除此地址吗": "Sei sicuro di voler eliminare questo indirizzo?",
    "提示": "Suggerimenti",
    "是": "Sì",
    "否": "No",
    "全部": "Tutti",
    "待处理": "In Attesa",
    "冻结中": "Congelamento",
    "已完成": "Completato",
    "订阅": "Iscrizione",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Vuoi essere informato quando lanciamo un nuovo template o un aggiornamento. Iscriviti e ti invieremo una notifica via email.",
    "我们的伙伴": "I nostri partner",
    "关于我们": "CHI SIAMO",
    "实时聊天支持": "Supporto Chat Live",
    "在线聊天": "Chat Live",
    "创建时间": "Data di Creazione",
    "你确定要登出当前账号吗？": "Vuoi disconnetterti?",
    "请输入你的邮箱": "Inserisci la tua Email",
    "好评": "Molto Buono",
    "条评论": "Recensioni",
    "平均每晚": "Media/Notte",
    "旅行旅程": "Viaggi",
    "正在寻找酒店": "In cerca di un viaggio",
    "开始预订": "Inizia Prenotazione",
    "旅程价格": "Prezzo del Viaggio",
    "佣金": "Commissioni",
    "底部-about": "Siamo un'agenzia di marketing digitale con sede in California, Stati Uniti, che consente alle aziende di avere successo nel mondo digitale attraverso strategie di marketing innovative e basate sui dati, servizio personalizzato e una mentalità orientata alla crescita che tiene il polso delle tendenze del settore. Che tu abbia bisogno di un'agenzia SEO, progettista di siti web, gestione pay per click, strategia di marca, sviluppo di contenuti o altri servizi di marketing digitale, Tours4fun ha l'esperienza e le competenze per aiutarti a raggiungere i tuoi obiettivi in un mondo orientato ai risultati.",
    "立即预订": "Prenota Ora",
    "搜索": "Cerca",
    "快乐的客户": "Cliente Felice",
    "快乐的酒店老板": "Proprietario dell'Hotel Felice",
    "评论1": "Architettura degli edifici.",
    "评论2": "Questo è un ottimo posto per una passeggiata con strade strette e una bella architettura sugli edifici.",
    "评论3": "Bella piazza tra la spiaggia e Central Avenue.",
    "评论4": "Edifici molto belli con una storia interessante.",
    "评论5": "Molti ristoranti tra cui scegliere e un'atmosfera molto rilassata.",
    "评论6": "Terra bellissima con alberi di arancio e animali.",
    "评论7": "Ottimo posto per una fuga nel fine settimana.",
    "评论8": "Luogo interessante ......",
    "评论9": "Un ottimo posto per rilassarsi e guardare il mondo che passa.",
    "评论10": "Club di spiaggia di alto livello che è un bel tributo a coloro che sono morti in guerra.",
    "评论11": "C'è così tanto da vedere ed è un ottimo posto per fare vacanza e rilassarsi!",
    "评论12": "Prima volta qui ed è stato molto piacevole, tornerò la prossima volta.",
    "评论13": "È stata un'esperienza diversa e mi sono sentito bene, i locali erano molto amichevoli e cortesi!",
    "推荐计划1": "Gli utenti della piattaforma possono invitare altri a diventare agenti della piattaforma tramite codici di riferimento e diventare parte della tua downline.",
    "推荐计划2": "Come upline, puoi estrarre una certa percentuale del profitto della tua downline, e il profitto ottenuto dall'upline sarà restituito direttamente al conto della piattaforma dell'upline, o al rapporto del team.",
    "推荐计划3": "Nota: Tutti gli agenti / downline della piattaforma riceveranno una certa percentuale dei profitti e delle ricompense della piattaforma di conseguenza, sviluppare un team non influisce sui profitti e sulle ricompense di alcun agente o downline.",
    "评分": "Valutazione",
    "评论": "Commento",
    "酒店很棒": "L'hotel è fantastico",
    "终于体验了，酒店非常好": "Finalmente l'ho provato, l'hotel è molto buono",
    "我对酒店非常满意并推荐预订": "Sono molto soddisfatto dell'hotel e consiglio di prenotare",
    "我很高兴很满意，价格实惠": "Sono molto felice e soddisfatto, il prezzo è accessibile",
    "服务非常好，我非常喜欢": "Il servizio è molto buono, mi piace molto",
    "体验很不错，朋友已经第二次预订了": "L'esperienza è stata molto buona, il mio amico ha già prenotato per la seconda volta",
    "酒店环境优美": "Ambiente dell'hotel bello",
    "酒店卫生干净": "L'hotel è igienico e pulito",
    "工作人员态度非常好": "L'atteggiamento del personale è molto buono",
    "酒店服务很棒": "Il servizio dell'hotel è fantastico",
    "你还没有设置支付密码，请前往设置": "Non hai impostato una password di pagamento, per favore vai nelle impostazioni",
    "订单": "Ordini",
    "复制成功": "Copia riuscita",
    "团队与条件": "Termini e Condizioni",
    "站内信": "Messaggi sul sito",
    "请联系客服升级会员": "Contatta il servizio clienti per l'upgrade della membership",
    "我已阅读并同意": "Ho letto e accettato il",
    "注册协议": "Accordo di Registrazione",
    "忘记密码": "Password Dimenticata",
    "账户明细": "Dettagli Account",
    "时间": "Tempo",
    "类型": "Tipo",
    "代理支持": "Supporto Agenti",
    "冻结金额": "Importo Congelato",

    "点击下面的金蛋抽取奖励": "Fai clic sull'uovo d'oro qui sotto per estrarre i premi",
    "恭喜您中奖了！": "Congratulazioni per aver vinto!",
    "很遗憾，您没有中奖": "Mi dispiace, non hai vinto",
    "公告": "annuncio",
}