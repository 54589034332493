export default {
    "title": "🇩🇪 Deutsch",
    
    "首页": "Startseite",
    "关于": "Über uns",
    "会员": "Mitgliedschaft",
    "推荐计划": "Empfehlungsprogramm",
    "个人": "Profil",
    "推荐": "Empfehlung",
    "预订": "Jetzt Reservieren",
    "事件": "Veranstaltung",
    "登出": "Abmelden",
    "登录": "Anmelden",
    "注册": "Registrieren",
    "我们的应用程序现在可用": "Unsere App jetzt verfügbar",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Für ein besseres Erlebnis haben wir gerade unsere mobilen Apps für nahtlose und schnellere Buchungen eingeführt.",
    "iOS版下载（即将推出）": "Download für iOS (Bald verfügbar)",
    "Android版下载（即将推出）": "Download für Android (Bald verfügbar)",
    "为什么选择我们": "Warum uns wählen",
    "我们卓越的服务。": "Unsere herausragenden Dienste.",
    "收入最高的酒店": "Hochverdienende Attraktionen",
    "我们的合作酒店收入最高。": "Unsere Partnerattraktionen mit den höchsten Einnahmen.",
    "回报": "Renditen",
    "最具特色的酒店": "Am meisten hervorgehobene Attraktionen",
    "我们平台上最有特色的酒店。": "Die am meisten hervorgehobene Unterkunft auf unserer Plattform.",
    "热门国家和目的地": "Top Länder & Ziele",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Entdecken Sie einige der besten Tipps aus der Stadt von unseren Partnern und Freunden.",
    "酒店": "Attraktionen",
    "预订酒店并开始赚取": "Erforschen Sie die Welt mit uns!",
    "让我们开始与TitanTraveler一起探索世界": "Machen Sie sich bereit, die Welt der Attraktionen mit uns zu erkunden!",
    "客户评价": "Kundenbewertungen",
    "查看我们用户的真实评价。": "Sehen Sie sich unsere authentischen Bewertungen von Nutzern an.",
    "银行卡列表": "Bankkartenliste",
    "银行列表": "Bankliste",
    "持卡人姓名": "Name des Karteninhabers",
    "银行卡号": "Bankkonto（ IBAN ）",
    "支付密码": "Zahlungs-Passwort",
    "提交": "Einreichen",
    "Pix列表": "Pix-Liste",
    "Pix类型": "Pix-Typ",
    "Pix账号": "Pix-Konto",
    "账号列表": "Kontenliste",
    "网络": "Netzwerk",
    "地址": "Adresse",
    "备注": "Hinweis",
    "充值金额": "Aufladebetrag",
    "支付类型": "Zahlungsart",
    "支付银行": "Zahlungsbank",
    "USDT地址": "USDT-Adresse",
    "二维码": "QR-Code",
    "充值渠道": "Aufladekanal",
    "充值凭证": "Aufladebeleg",
    "立即登录": "Jetzt Anmelden",
    "用户名": "Benutzername",
    "密码": "Passwort",
    "邀请码": "Einladungscode",
    "确认密码": "Passwort bestätigen",
    "手机": "Mobiltelefon",
    "注册2": "Registrieren",
    "金额": "Betrag",
    "新增": "Neu",
    "我们的精彩": "Unser Großartiges",
    "故事": "Geschichte",
    "奖项": "Auszeichnungen",
    "获得": "Erhalten",
    "钱包余额": "Guthaben",
    "今日奖励": "Heutige Belohnungen",
    "总行程": "Gesamtreise",
    "访问行程": "Besuchte Reisen",
    "酒店历史": "Bestelldetails",
    "单价": "Preis",
    "数量": "Anzahl",
    "总额": "Gesamtbetrag",
    "奖励": "Belohnungen",
    "上一页": "Vorherige Seite",
    "下一页": "Nächste Seite",
    "每套预订量": "Buchungen pro Set",
    "预订奖励": "Buchungsbelohnung",
    "每日预订": "Tägliche Buchung",
    "手机号码": "Telefonnummer",
    "修改密码": "Passwort ändern",
    "登录密码": "Anmeldepasswort",
    "当前密码": "Aktuelles Passwort",
    "新密码": "Neues Passwort",
    "确认新密码": "Neues Passwort bestätigen",
    "保存修改": "Änderungen speichern",
    "充值": "Aufladen",
    "我们的": "Unser",
    "复制": "Kopieren",
    "分享链接": "Link teilen",
    "搜索酒店": "Reise reservieren",
    "实际等级": "Tatsächliches Niveau",
    "升级": "Upgrade",
    "已完成预订": "Abgeschlossene Buchungen",
    "登录时间": "Anmeldezeit",
    "信誉分": "Glaubwürdigkeit",
    "控制菜单": "Dashboard-Menü",
    "提现": "Abheben",
    "提现账号": "Abhebekonto",
    "你确定要删除此账号吗": "Sind Sie sicher, dass Sie dieses Konto löschen möchten?",
    "你确定要删除此地址吗": "Sind Sie sicher, dass Sie diese Adresse löschen möchten?",
    "提示": "Tipps",
    "是": "Ja",
    "否": "Nein",
    "全部": "Alle",
    "待处理": "Ausstehend",
    "冻结中": "Eingefroren",
    "已完成": "Abgeschlossen",
    "订阅": "Abonnieren",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Möchten Sie benachrichtigt werden, wenn wir ein neues Template oder ein Update herausbringen? Melden Sie sich einfach an und wir senden Ihnen eine Benachrichtigung per E-Mail.",
    "我们的伙伴": "Unsere Partner",
    "关于我们": "ÜBER UNS",
    "实时聊天支持": "Live-Chat-Support",
    "在线聊天": "Online-Chat",
    "创建时间": "Erstellungszeit",
    "你确定要登出当前账号吗？": "Möchten Sie sich wirklich von diesem Konto abmelden?",
    "请输入你的邮箱": "Geben Sie Ihre E-Mail-Adresse ein",
    "好评": "Sehr gut",
    "条评论": "Bewertungen",
    "平均每晚": "Durchschnitt/Nacht",
    "旅行旅程": "Reiseverlauf",
    "正在寻找酒店": "Auf der Suche nach einer Reise",
    "开始预订": "Reservierung starten",
    "旅程价格": "Reisepreis",
    "佣金": "Provisionen",
    "底部-about": "Wir sind eine Digital-Marketing-Agentur mit Sitz in Kalifornien, USA, die Unternehmen hilft, im digitalen Bereich durch innovative und datengestützte Marketingstrategien, personalisierten Service und eine Wachstumsmentalität, die den Puls der Branchentrends hält, erfolgreich zu sein. Egal, ob Sie eine SEO-Agentur, Webdesigner, PPC-Management, Markenstrategie, Content-Entwicklung oder andere digitale Marketingdienstleistungen benötigen, Tours4fun verfügt über das Fachwissen und die Erfahrung, um Ihnen zu helfen, Ihre Ziele in einer ergebnisorientierten Welt zu erreichen.",
    "立即预订": "Jetzt buchen",
    "搜索": "Suche",
    "快乐的客户": "Zufriedene Kunden",
    "快乐的酒店老板": "Zufriedene Hotelbesitzer",
    "评论1": "Architektur der Gebäude.",
    "评论2": "Dies ist ein großartiger Ort für einen Spaziergang mit engen Straßen und schöner Architektur der Gebäude.",
    "评论3": "Schöner Platz zwischen dem Strand und der Central Avenue.",
    "评论4": "Sehr schöne Gebäude mit interessanter Geschichte.",
    "评论5": "Viele Restaurants zur Auswahl und eine sehr entspannte Atmosphäre.",
    "评论6": "Schöne Landschaft mit herrlichen Orangenplantagen und Tieren.",
    "评论7": "Großartiger Ort für einen Wochenendausflug.",
    "评论8": "Interessanter Ort ......",
    "评论9": "Ein großartiger Ort, um sich zurückzulehnen und die Welt vorbeiziehen zu lassen.",
    "评论10": "Hochwertiger Strandclub, der eine schöne Hommage an die Gefallenen im Krieg ist.",
    "评论11": "Es gibt so viel zu sehen und es ist ein großartiger Ort für einen Urlaub und zum Entspannen!",
    "评论12": "Das erste Mal hier und es war sehr angenehm, ich werde beim nächsten Mal wiederkommen.",
    "评论13": "Es war eine andere Erfahrung und fühlte sich großartig an, die Einheimischen waren sehr freundlich und höflich!",
    "推荐计划1": "Plattformbenutzer können andere einladen, Plattformagenten durch Empfehlungslinks zu werden und Teil Ihres Netzwerks zu werden.",
    "推荐计划2": "Als Upline können Sie einen bestimmten Prozentsatz des Gewinns Ihrer Downline entnehmen, und der Gewinn, den die Upline erzielt, wird direkt dem Plattformkonto der Upline oder dem Teambericht gutgeschrieben.",
    "推荐计划3": "Hinweis: Alle Agenten/Downlines der Plattform erhalten einen bestimmten Prozentsatz der Gewinne und Belohnungen der Plattform entsprechend, die Entwicklung eines Teams beeinflusst nicht die Gewinne und Belohnungen anderer Agenten oder Downlines.",
    "评分": "Bewertung",
    "评论": "Kommentar",
    "酒店很棒": "Das Hotel ist großartig",
    "终于体验了，酒店非常好": "Endlich erlebt, das Hotel ist sehr gut",
    "我对酒店非常满意并推荐预订": "Ich bin mit dem Hotel sehr zufrieden und empfehle die Buchung",
    "我很高兴很满意，价格实惠": "Ich bin sehr glücklich und zufrieden, der Preis ist erschwinglich",
    "服务非常好，我非常喜欢": "Der Service ist sehr gut, ich mag ihn sehr",
    "体验很不错，朋友已经第二次预订了": "Die Erfahrung war sehr gut, mein Freund hat es bereits zum zweiten Mal gebucht",
    "酒店环境优美": "Schöne Hotelumgebung",
    "酒店卫生干净": "Hotel ist hygienisch und sauber",
    "工作人员态度非常好": "Die Einstellung des Personals ist sehr gut",
    "酒店服务很棒": "Der Hotelservice ist großartig",
    "你还没有设置支付密码，请前往设置": "Sie haben kein Zahlungs-Passwort festgelegt, bitte gehen Sie zu den Einstellungen",
    "订单": "Bestellungen",
    "复制成功": "Kopieren erfolgreich",
    "团队与条件": "Allgemeine Geschäftsbedingungen",
    "站内信": "Nachrichten auf der Webseite",
    "请联系客服升级会员": "Bitte kontaktieren Sie den Kundenservice für das Upgrade der Mitgliedschaft",
    "我已阅读并同意": "Ich habe gelesen und stimme zu",
    "注册协议": "Registrierungsvereinbarung",
    "忘记密码": "Passwort vergessen",
    "账户明细": "Kontodetails",
    "时间": "Zeit",
    "类型": "Typ",
    "代理支持": "Agentenunterstützung",
    "冻结金额": "Eingefrorener Betrag",

    "点击下面的金蛋抽取奖励": "Klicken Sie unten auf das goldene Ei, um Belohnungen zu erhalten",
    "恭喜您中奖了！": "Herzlichen Glückwunsch zum Gewinn!",
    "很遗憾，您没有中奖": "Entschuldigung, Sie haben nicht gewonnen",
    "公告": "Ankündigung",
}