import { createI18n } from 'vue-i18n'
import { useLocalStorage } from '@vueuse/core'
import zhHK from './language/zh-hk'
import zhch from './language/zh-ch'
import en from './language/en'
import fr from './language/fr'
import jp from './language/jp'
import nl from './language/nl'
import th from './language/th'
import vn from './language/vn'
import es from './language/es'
import it from './language/it'
import de from './language/de'

/**
 * Amount formatting
 */
export function formatAmountFunc(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


const langRef = useLocalStorage('language', 'en')

var messages = {
  'en': en,
  'fr': fr,
  'nl': nl,
  'de': de,
  'it': it,
  'es': es,
  // 'nl': nl,
  // 'jp': jp,
  // 'vn': vn,
  // 'th': th,
  'zh-ch': zhch,
}

const i18n = createI18n({ locale: langRef.value, messages })

const languageList = Object.keys(messages).map(k =>
  ({ label: messages[k].title, value: k })
)

const setLanguage = (item = { label: '', value: '' }) => {
  i18n.locale = item.value
  langRef.value = item.value
  setTimeout(() => {
    location.reload()
  }, 500);
}

const t = i18n.global.t

const moneyFlag = process.env.VUE_APP_MONEY_FLAG

const fm = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return `${moneyFlag} ${_val}`
}

const fn = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return _val
}

export default i18n
export { t, langRef, languageList, setLanguage, fm, fn }
