export default {
    "title": "🇨🇳 中文简体",

    "首页": "首页",
    "关于": "关于",
    "会员": "会员",
    "推荐计划": "推荐计划",
    "个人": "个人",
    "推荐": "推荐",
    "预订": "预订",
    "事件": "事件",
    "登出": "登出",
    "登录": "登录",
    "注册": "注册",
    "我们的应用程序现在可用": "我们的应用程序现在可用",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。",
    "iOS版下载（即将推出）": "iOS版下载（即将推出）",
    "Android版下载（即将推出）": "Android版下载（即将推出）",
    "为什么选择我们": "为什么选择我们",
    "我们卓越的服务。": "我们卓越的服务。",
    "收入最高的酒店": "收入最高的酒店",
    "我们的合作酒店收入最高。": "我们的合作酒店收入最高。",
    "回报": "回报",
    "最具特色的酒店": "最具特色的酒店",
    "我们平台上最有特色的酒店。": "我们平台上最有特色的酒店。",
    "热门国家和目的地": "热门国家和目的地",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。",
    "酒店": "酒店",
    "预订酒店并开始赚取": "预订酒店并开始赚取",
    "让我们开始与TitanTraveler一起探索世界": "让我们开始与TitanTraveler一起探索世界",
    "客户评价": "客户评价",
    "查看我们用户的真实评价。": "查看我们用户的真实评价。",
    "银行卡列表": "银行卡列表",
    "银行列表": "银行列表",
    "持卡人姓名": "持卡人姓名",
    "银行卡号": "银行卡号（ IBAN ）",
    "支付密码": "支付密码",
    "提交": "提交",
    "Pix列表": "Pix列表",
    "Pix类型": "Pix类型",
    "Pix账号": "Pix账号",
    "账号列表": "账号列表",
    "网络": "网络",
    "地址": "地址",
    "备注": "备注",
    "充值金额": "充值金额",
    "支付类型": "支付类型",
    "支付银行": "支付银行",
    "USDT地址": "USDT地址",
    "二维码": "二维码",
    "充值渠道": "充值渠道",
    "充值凭证": "充值凭证",
    "立即登录": "立即登录",
    "用户名": "用户名",
    "密码": "密码",
    "邀请码": "邀请码",
    "确认密码": "确认密码",
    "手机": "手机",
    "注册2": "注册",
    "金额": "金额",
    "新增": "新增",
    "我们的精彩": "我们的精彩",
    "故事": "故事",
    "奖项": "奖项",
    "获得": "获得",
    "钱包余额": "钱包余额",
    "今日奖励": "今日奖励",
    "总行程": "总行程",
    "访问行程": "访问行程",
    "酒店历史": "订单明细",
    "单价": "单价",
    "数量": "数量",
    "总额": "总额",
    "奖励": "奖励",
    "上一页": "上一页",
    "下一页": "下一页",
    "每套预订量": "每套预订量",
    "预订奖励": "预订奖励",
    "每日预订": "每日预订",
    "手机号码": "手机号码",
    "修改密码": "修改密码",
    "登录密码": "登录密码",
    "当前密码": "当前密码",
    "新密码": "新密码",
    "确认新密码": "确认新密码",
    "保存修改": "保存修改",
    "充值": "充值",
    "我们的": "我们的",
    "复制": "复制",
    "分享链接": "分享链接",
    "搜索酒店": "搜索酒店",
    "实际等级": "实际等级",
    "升级": "升级",
    "已完成预订": "已完成预订",
    "登录时间": "登录时间",
    "信誉分": "信誉分",
    "控制菜单": "控制菜单",
    "提现": "提现",
    "提现账号": "提现账号",
    "你确定要删除此账号吗": "你确定要删除此账号吗",
    "你确定要删除此地址吗": "你确定要删除此地址吗",
    "提示": "提示",
    "是": "是",
    "否": "否",
    "全部": "全部",
    "待处理": "待处理",
    "冻结中": "冻结中",
    "已完成": "已完成",
    "订阅": "订阅",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。",
    "我们的伙伴": "我们的伙伴",
    "关于我们": "关于我们",
    "实时聊天支持": "实时聊天支持",
    "在线聊天": "在线聊天",
    "创建时间": "创建时间",
    "你确定要登出当前账号吗？": "你确定要登出当前账号吗？",
    "请输入你的邮箱": "请输入你的邮箱",
    "好评": "好评",
    "条评论": "条评论",
    "平均每晚": "平均每晚",
    "旅行旅程": "旅行旅程",
    "正在寻找酒店": "正在寻找酒店",
    "开始预订": "开始预订",
    "旅程价格": "旅程价格",
    "佣金": "佣金",
    "底部-about": "我们是一家总部位于德国的数字营销机构，通过创新和数据驱动的营销策略、个性化服务以及保持对行业趋势脉搏的增长思维，帮助企业在数字世界取得成功。无论您需要 SEO 机构、网站设计师、按点击付费管理、品牌战略、内容开发或其他数字营销服务，德图集团拥有专业知识和经验，帮助您在以结果为导向的世界实现目标。",
    "立即预订": "立即预订",
    "搜索": "搜索",
    "快乐的客户": "快乐的客户",
    "快乐的酒店老板": "快乐的酒店老板",
    "评论1": "建筑上的建筑。",
    "评论2": "这是一个适合散步的好地方，有狭窄的街道和一些美丽的建筑。",
    "评论3": "美丽的广场，位于海滩和中央大道之间。",
    "评论4": "非常漂亮的建筑，有着有趣的历史。",
    "评论5": "有很多餐馆可供选择，氛围非常轻松。",
    "评论6": "美丽的土地，有可爱的橙子园和动物。",
    "评论7": "周末度假的好地方。",
    "评论8": "有趣的地方......",
    "评论9": "一个放松身心、观察世界转动的好地方。",
    "评论10": "高端海滩俱乐部，是对战争中逝者的美好致敬。",
    "评论11": "有太多值得看的地方，是度假和放松的好地方！",
    "评论12": "第一次来这里，感觉非常愉快，下次还会再来。",
    "评论13": "这是一次不同的体验，感觉很好，当地人非常友好和礼貌！",
    "推荐计划1": "平台用户可以通过推荐码邀请其他人成为平台代理商，成为您的下线。",
    "推荐计划2": "作为上线，您可以从下线的利润中抽取一定比例，上线获得的利润将直接返还至上线的平台账户，或者返还至团队报表。",
    "推荐计划3": "注：平台所有代理/下线都会相应获得平台一定比例的利润和奖励，组建团队不影响任何代理或下线的利润和奖励。",
    "评分": "评分",
    "评论": "评论",
    "酒店很棒": "酒店很棒",
    "终于体验了，酒店非常好": "终于体验了，酒店非常好",
    "我对酒店非常满意并推荐预订": "我对酒店非常满意并推荐预订",
    "我很高兴很满意，价格实惠": "我很高兴很满意，价格实惠",
    "服务非常好，我非常喜欢": "服务非常好，我非常喜欢",
    "体验很不错，朋友已经第二次预订了": "体验很不错，朋友已经第二次预订了",
    "酒店环境优美": "酒店环境优美",
    "酒店卫生干净": "酒店卫生干净",
    "工作人员态度非常好": "工作人员态度非常好",
    "酒店服务很棒": "酒店服务很棒",
    "你还没有设置支付密码，请前往设置": "你还没有设置支付密码，请前往设置",
    "订单": "订单",
    "复制成功": "复制成功",
    "团队与条件": "团队与条件",
    "站内信": "站内信",
    "请联系客服升级会员": "请联系客服升级会员",
    "我已阅读并同意": "我已阅读并同意",
    "注册协议": "注册协议",
    "忘记密码": "忘记密码",
    "账户明细": "账户明细",
    "时间": "时间",
    "类型": "类型",
    "代理支持": "代理支持",
    "冻结金额": "冻结金额",

    "点击下面的金蛋抽取奖励": "点击下面的金蛋抽取奖励",
    "恭喜您中奖了！": "恭喜您中奖了！",
    "很遗憾，您没有中奖": "很遗憾，您没有中奖",
    "公告": "公告",
    // "aaa": "vvv",
    // "aaa": "vvv",
}